<template>
  <main class="page-container" id="sme-we-view">
    <div class="page-component">
      <div class="page-header screen-out">
        <div class="page-component">
          <div class="page-header-title">
            <h2 class="title">소상공인 인터뷰</h2>
          </div>
        </div>
      </div>
      <div class="page-body" style="position: relative">
        <section class="post-view-wrapper">
          <header class="post-view-header">
            <div class="header-category">
              <div class="header-interview">
                <span class="interview-text">INTERVIEW {{ params.interviewNo }}</span>
              </div>
            </div>
            <h3 class="header-title">{{ params.interviewTit }}</h3>
          </header>
          <div class="post-view-body">
            <div class="gachi-view" v-html="params.interviewCn"></div>
          </div>
        </section>
        <div v-if="isMobile" class="page-buttons" data-align="center" style="flex-direction: row;width: 100%;margin: auto">
          <a href="javascript:">
            <next-prev-button
                route-name="InterviewView"
                param-key="interviewId"
                :pre-id="params.preId"
                :next-id="params.nextId"
            ></next-prev-button>
          </a>
        </div>
        <div v-else class="page-buttons" data-align="center" style="flex-direction: row;width: 900px;margin: auto">
          <a href="javascript:">
            <next-prev-button
                route-name="InterviewView"
                param-key="interviewId"
                :pre-id="params.preId"
                :next-id="params.nextId"
            ></next-prev-button>
          </a>
        </div>
        <a href="javascript:">
          <div v-if="isMobile" class="list-btn-cont" style="width: 100px;position: absolute;left: calc(50% - 50px);top: calc(100% - 80px);">
            <router-link :to="{name: 'Interview'}" class="btn btn-lg btn-primary" >
              <span class="text">목록</span>
            </router-link>
          </div>
          <div v-else class="list-btn-cont" style="width: 120px;position: absolute;left: calc(50% - 60px);top: calc(100% - 60px);">
            <router-link :to="{name: 'Interview'}" class="btn btn-lg btn-primary" >
              <span class="text">목록</span>
            </router-link>
          </div>
        </a>
      </div>
    </div>
  </main>
</template>

<script>
import {ACT_GET_INTERVIEW} from '../../../store/_act_consts';
import {getItem, lengthCheck, queryToValue, setParams} from '../../../assets/js/utils';
import {viewCount} from '../../../assets/js/func';
import NextPrevButton from '../../../components/common/NextPrevButton';
import {mapGetters} from "vuex";

export default {
  name: 'InterviewView',
  components:{
    NextPrevButton
  },
  watch:{
    $route(to){
      this.params.interviewId = queryToValue(to.params.interviewId, true, 0);
      this.getInterview();
    }
  },
  data:()=> ({
    params: {
      interviewId: 0,
      interviewNo: 0,
      interviewTit: '',
      interviewSmlTit: '',
      interviewCn: '',
      preId: 0,
      nextId: 0
    }
  }),
  computed: {
    ...mapGetters('common', ['isMobile'])

  },
  created() {
    this.params.interviewId = this.$route.params.interviewId;
    viewCount(this.params.interviewId, 'interview');
    this.getInterview();
  },
  methods: {
    getInterview() {
      this.$store.dispatch(`information/${ACT_GET_INTERVIEW}`, this.params.interviewId)
      .then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(this.params, item);
        }else {
          this.params.interviewId = 0;
        }
      }).catch(e=>{
        console.error(e);
        this.params.interviewId = 0;
      })
    },
    // clickMove(type){
    //   const paramId = (type === 'prev' ? this.params.preId : this.params.nextId);
    //   this.$router.push({name: 'InterviewView', params: {interviewId: paramId}}).catch(()=>{});
    // }
  }
};
</script>
